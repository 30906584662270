import gsap from 'gsap';

import { instances } from '../../utils/store';

export default class Awards {
  constructor() {
    this.section = document.getElementById('s-awards');

    this.list = this.section.querySelector('.list');
    this.button = this.section.querySelector('button');
    this.overview = this.section.querySelector('.overview');

    this.years = this.list.querySelectorAll('.year');

    this.active = false;
  }

  init = () => {
    this.button.addEventListener('click', this.toggle);
  }

  toggle = () => {
    if (this.active === false) {
      this.active = true;

      let height = 0;

      [...this.years].forEach((i) => {
        const b = i.getBoundingClientRect().height;
        height = height + b;
      });

      gsap.to(this.list, {
        height,
        duration: 1,
        ease: 'expo.inOut',
        onComplete: () => {
          this.button.innerHTML = 'â†‘ Show Less';
          instances.scroll.resize();
        }
      });
    } else {
      this.active = false;

      gsap.to(this.list, {
        height: 1,
        duration: 1,
        ease: 'expo.inOut',
        onComplete: () => {
          const b = this.overview.getBoundingClientRect().top;
          const o = -b + instances.scroll.data.current;
          instances.scroll.data.current = o;
          this.button.innerHTML = 'â†“ Show More';
          instances.scroll.resize();
        }
      });
    }
  }

  destroy = () => { }
}
