import gsap from 'gsap';
import Highway from '@dogstudio/highway';

class Default extends Highway.Transition {
  in({ from, to, done }) {

    this.window = {
      w: window.innerWidth,
      h: window.innerHeight,
    };

    this.menu = {
      e: document.getElementById('a-menu'),
      d: document.querySelector('.menu-d'),
      l: document.querySelector('.menu-l'),
    };

    gsap.set(this.menu.e, {
      clearProps: 'all',
    });

    // â†
    if (document.body.classList.contains('is-dark')) {

      gsap.set(this.menu.l, {
        clip: `rect(0px ${this.window.w}px ${this.window.h}px 0px)`,
      });

      gsap.set(this.menu.d, {
        clip: `rect(0px ${this.window.w}px ${this.window.h}px ${this.window.w}px)`,
      });

      // â†’
    } else {

      gsap.set(this.menu.l, {
        clip: `rect(0px 0px ${this.window.h}px 0px)`,
      });

      gsap.set(this.menu.d, {
        clip: `rect(0px ${this.window.w}px ${this.window.h}px 0px)`,
      });
    }

    from.remove();
    done();
  }

  out({ from, done }) {
    done();
  }
}

export default Default;
