import gsap from 'gsap';
import Highway from '@dogstudio/highway';

// store
import { instances } from '../utils/store';

class Back extends Highway.Transition {
  in({ from, to, done }) {

    this.window = {
      w: window.innerWidth,
      h: window.innerHeight,
    };

    this.scroll = to.querySelector('.a-scroll');

    // â† work
    if (to.classList.contains('is-work')) {
      gsap.set(this.scroll, {
        y: instances.offset,
      });
    }

    gsap.fromTo(from,
      {
        clip: `rect(0px ${this.window.w}px ${this.window.h}px 0px)`,
      }, {
      duration: 1.5,
      clearProps: 'all',
      ease: 'expo.inOut',
      clip: `rect(0px ${this.window.w}px 0px 0px)`,

      onComplete: () => {
        from.remove();
        done();
      },
    }
    );
  }

  out({ from, done }) {
    done();
  }
}

export default Back;
