import gsap from 'gsap';
import Sniffer from 'sniffer';
import imagesLoaded from 'imagesloaded';
import VirtualScroll from 'virtual-scroll';

import { instances, math } from '../../utils/store';

export default class Scroll {
  constructor() {
    this.section = document.querySelector('.a-scroll');

    this.data = {
      last: 0,
      ease: 0.1,
      current: 0,
    };

    this.scrolling = false;
  }

  init = () => {
    if (Sniffer.isDesktop && instances.ipad === false) {
      this.vs = new VirtualScroll();

      this.vs.options.touchMultiplier = 2.5;
      this.vs.options.mouseMultiplier = 0.45;
      this.vs.options.firefoxMultiplier = 90;

      this.vs.options.passive = true;
      this.vs.options.limitInertia = false;

      imagesLoaded(this.section, () => {
        this.events();
        this.enableSmooth();
      });
    } else {
      imagesLoaded(this.section, () => {
        this.events();
        this.enableNative();
      });
    }
  }

  events = () => {
    window.addEventListener('resize', this.resize, { passive: true });
  }

  enableNative = () => {
    gsap.ticker.add(this.native);
  }

  disableNative = () => {
    gsap.ticker.remove(this.native);
  }

  enableSmooth = () => {
    if (instances.offset && document.getElementById('s-work')) {
      this.data.current = instances.offset;
      this.data.last = instances.offset;
      instances.offset = undefined;
    }

    this.vs.on(this.calc);
    gsap.ticker.add(this.smooth);
    this.bounding = this.section.getBoundingClientRect();
  }

  disableSmooth = () => {
    this.vs.destroy();
    gsap.ticker.remove(this.smooth);
    window.removeEventListener('resize', this.resize, { passive: true });
  }

  destroy = () => {
    if (Sniffer.isDesktop && instances.ipad === false) {
      this.disableSmooth();
    } else {
      this.disableNative();
    }
  }

  native = () => {
    if (instances.loading === true) return;

    this.data.current = this.section.getBoundingClientRect().top;

    this.data.last = math.lerp(this.data.last, this.data.current, this.data.ease);

    if (Math.round(this.data.last) === Math.round(this.data.current)) {
      this.scrolling = false;
    } else {
      this.scrolling = true;
    }

    if (this.data.abs < 0.1) {
      this.data.last = 0;
    }
  }

  smooth = () => {
    if (instances.loading === true) return;

    this.data.last = math.lerp(this.data.last, this.data.current, this.data.ease);

    this.data.abs = Math.abs(this.data.last);

    if (Math.round(this.data.last) === Math.round(this.data.current)) {
      this.scrolling = false;
    } else {
      this.scrolling = true;
    }

    if (this.data.abs < 0.1) {
      this.data.last = 0;
    }

    this.section.style.transform = `translate3d(0px, ${this.data.last}px, 0px)`;
  }

  calc = (e) => {
    if (instances.loading === true) return;

    this.data.current += e.deltaY;
    this.data.current = Math.max((this.bounding.height - window.innerHeight) * -1, this.data.current);
    this.data.current = Math.min(0, this.data.current);
  }

  resize = () => {
    this.bounding = this.section.getBoundingClientRect();
  }
}
